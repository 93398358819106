
@page {
    size: A4 portrait;
    margin: 0.2in;
}

@media print {
    body{
        counter-reset: page;
    }
    thead {
        display: table-header-group;
    }
    tbody {
        display: table-row-group;
    }
    tfoot {
        display: table-footer-group;
    }
    tfoot:after {
        /* counter-increment: page;
        content:"Page " counter(page); */
        content: counter(page) ' / ' counter(pages);
        position: fixed;
        bottom: 5px;
    }
   
    tfoot:before {
        content:'';
        height: 1px;
        width: 100%;
        bottom: 30px;
        position: fixed;
        background-color: black;
    }
}
.quotation-table table, .quotation-table td, .quotation-table th {
    
    border-collapse: collapse;
}